<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Cari Yönetimi | Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Cari Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Cari Listesi",
          href: "/current-account",
        },
        {
          text: "Cari Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: [],
      type: 0,
      account_holder: "",
      currency: [],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: "id", sortable: true, label: "İşlem Numarası" },
        { key: "date", sortable: true, label: "İşlem Tarihi" },
        { key: "type", sortable: true, label: "İşlem Türü" }, // currency
        { key: "comment", sortable: true, label: "İşlem Açıklaması" },
        { key: "total", sortable: true, label: "İşlem Tutarı" },
        { key: "nextTotal", sortable: true, label: "İşlem Sonrası Bakiye" },
        { key: "actions", label: "İşlemler" },
      ],
      historyType: [
        { id: 1, text: "Giriş" },
        { id: 2, text: "Çıkış" }
      ],
      history: {
        date: "",
        type: "",
        comment: "",
        balance: ""
      },
      value_items: "",
      types: [
        { value: 1, name: "Personel" },
        { value: 2, name: "Acenta" },
        { value: 3, name: "Otel" },
        { value: 4, name: "Müşteri" },
      ],
    };
  },
  mounted() {
    this.getData(), this.getWhere();
  },
  watch: {
    type(value) {
      if (value.name == "Personel") {
        this.getDatas("/employee/getAll", "value_items");
      }
      if (value.name == "Acenta") {
        this.getDatas("/agency/getAll", "value_items");
      }
      if (value.name == "Otel") {
        this.getDatas("/hotel/getAll", "value_items");
      }
      if (value.name == "Müşteri") {
        this.getDatas("/customer/getAll", "value_items");
      }
    }
  },
  methods: {
    getDatas(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/current-account/${itemId}`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = JSON.parse(response.data?.data.values);
          this.type = response.data?.data.type;
          this.account_holder = response.data?.data.account_holder;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getWhere() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/current-account/history/" + itemId + "?page=" + this.currentPage;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.tableData = response.data?.data;
          this.totalRows = response.data?.data.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    update() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/current-account/${itemId}`;
      const data = {
        values: JSON.stringify(this.values),
        type: this.type.value,
        account_holder: this.account_holder,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 col-md-12">
        <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
        <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="bank">Hesap Adı</label>
                    <input v-model="account_holder" type="text" class="form-control" id="bank" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="acccount_holder">Hesap Türü</label>
                    <multiselect v-model="type" :options="types" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="type">Cari</label>
                    <multiselect v-model="values" :options="value_items" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title m-0">İşlem Listesi</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-12">
                <div class="table-responsive mb-0">
                  <b-table :items="tableData" :fields="fields" responsive="sm" :current-page="currentPage">
                    <template #cell(type)="data">
                      <b-badge :variant="data.item.type.variant">
                        {{ data.item.type.text }}
                      </b-badge>
                    </template>
                    <template v-slot:cell(balanceBelongsCurrency)="data">
                      {{ data.item.balance + " " + data.item.currencySymbol }}
                    </template>
                    <template v-slot:cell(actions)="data">
                      <button @click="deleteItem(data.item.id)" class="btn btn-sm btn-danger ml-2">
                        Sil
                      </button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" @change="onPageChange">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </Layout>
</template>
